<script>

import JtrWords from '../translations/jtr_home.js';

export default {
    name : 'Jtre',
    methods : {
        jtre(text) {
            for (var i = 0; i < JtrWords.length; i++) {
                if (JtrWords[i].id == text) {
                    return JtrWords[i].fi;
                }
            }
            return text;
        }
    }
}
</script>
