<template>
    <div id="footer">
        <div class="container">
            <div class="box">
            <div class="content has-text-centered">
                <div class="columns" style="font-size : small">
                    <div class="column">
                        &#169; <a href="https://www.kirjavalitys.fi" >Kirjavälitys Oy</a> 2015 - {{ year }}
                    </div>
                    <div class="column">
                        <a href="/Opetusaineistot.kirjavalitys.fi tietosuojaseloste.pdf" target="_blank"><Jtr>GDPR</Jtr></a>
                    </div>
                    <div class="column is-half">
                        eHelpDesk: <Jtr>Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm</Jtr>
                    </div>
                    <div class="column">
                        <a href="mailto:ehelpdesk@storia.fi">ehelpdesk@storia.fi</a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Jtr from '../components/Jtr.vue';

export default {
  name: 'Footer',
  props: {
  },
  components: {
      Jtr
  },
  data: function() {
      return {
          year : ""
      }
  },
  created : function() {
    this.year = new Date().getFullYear();
  },

  methods: {
  },

  watch: {
  }
}
</script>
