var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "hero is-success is-fullheight" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c(
          "div",
          {
            staticClass: "container has-text-centered",
            staticStyle: { "max-width": "500px" }
          },
          [
            _c("div", { staticClass: "columns is-vcentered" }, [
              _c("div", { staticClass: "column" }, [
                _vm.info != ""
                  ? _c("div", [
                      _c("div", { attrs: { id: "info" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "notification is-warning is-light",
                            staticStyle: { color: "black" }
                          },
                          [
                            _c("button", {
                              staticClass: "delete",
                              on: {
                                click: function($event) {
                                  _vm.info = ""
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("strong", [_vm._v(_vm._s(_vm.topic))]),
                            _vm._v(" "),
                            _c("p"),
                            _vm._v(" "),
                            _c("div", {
                              domProps: { innerHTML: _vm._s(_vm.info) }
                            })
                          ]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "box circle" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h3", { staticClass: "title has-text-black" }, [
                    _vm._v("Opetusaineistot")
                  ]),
                  _vm._v(" "),
                  !_vm.gdprAccepted
                    ? _c("div", [
                        _c("div", { staticClass: "notification is-danger" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.gdprMsg) +
                              "\n                      "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p"),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            on: {
                              click: function($event) {
                                return _vm.reloadBanner()
                              }
                            }
                          },
                          [_c("Jtr", [_vm._v("Ask again")])],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.gdprAccepted
                    ? _c("form", { attrs: { method: "post" } }, [
                        _c("div", { staticClass: "field" }, [
                          _c("div", { staticClass: "control has-icons-left" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email"
                                }
                              ],
                              staticClass: "input is-primary is-medium",
                              attrs: {
                                name: "username",
                                type: "text",
                                placeholder: _vm.username_string,
                                autofocus: ""
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "icon is-small is-left" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "user" }
                                }),
                                _vm._v(" \n                              ")
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "help is-primary" },
                            [
                              _c("Jtr", [
                                _vm._v(
                                  "Please use either your email or username - firstname.lastname is not in use"
                                )
                              ])
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c("div", { staticClass: "control has-icons-left" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password"
                                }
                              ],
                              staticClass: "input is-primary is-medium",
                              attrs: {
                                name: "password",
                                type: "password",
                                placeholder: _vm.password_string
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.password = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "icon is-small is-left" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "key" }
                                }),
                                _vm._v(" \n                              ")
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "is-lightgray" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "field is-grouped" }, [
                          _c("div", { staticClass: "control" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button is-block is-primary",
                                attrs: { name: "login", type: "submit" }
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "sign-in-alt" }
                                }),
                                _vm._v(" \n                        "),
                                _c("Jtr", [_vm._v("Sign in")])
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "control" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-block is-info",
                                attrs: { href: "/v1/mpass/direct" }
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "sign-in-alt" }
                                }),
                                _vm._v(" \n                        "),
                                _c("Jtr", [_vm._v("Sign in with MPASSid")])
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field is-grouped" }, [
                          _c(
                            "div",
                            {
                              staticClass: "control",
                              staticStyle: { "font-size": "x-small" }
                            },
                            [
                              _vm._v("\n                          eHelpDesk: "),
                              _c("Jtr", [
                                _vm._v(
                                  "Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm"
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._m(1)
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.gdprAccepted
                  ? _c("p", { staticClass: "has-text-grey" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.dialogOpen(1)
                            }
                          }
                        },
                        [_c("Jtr", [_vm._v("Register")])],
                        1
                      ),
                      _vm._v("  · \n                  "),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.dialogOpen(2)
                            }
                          }
                        },
                        [_c("Jtr", [_vm._v("Forgot password?")])],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "has-text-grey" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/Opetusaineistot.kirjavalitys.fi tietosuojaseloste.pdf",
                        target: "_blank"
                      }
                    },
                    [_c("Jtr", [_vm._v("GDPR")])],
                    1
                  ),
                  _vm._v("\n                     · \n                    "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/Opetusaineistot-käyttöehdot.pdf",
                        target: "_blank"
                      }
                    },
                    [_c("Jtr", [_vm._v("Terms of Use")])],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.msg != ""
                  ? _c("div", [
                      _c("div", { attrs: { id: "msg" } }, [
                        _c("div", { staticClass: "notification is-warning" }, [
                          _c("button", {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                _vm.msg = ""
                              }
                            }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.msg) +
                              "\n                        "
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.dialogVisible
                  ? _c("div", { staticClass: "modal is-active" }, [
                      _c("div", { staticClass: "modal-background" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-card" }, [
                        _c("header", { staticClass: "modal-card-head" }, [
                          _c(
                            "p",
                            {
                              staticClass: "modal-card-title",
                              attrs: { id: "sup_title" }
                            },
                            [_vm._v(_vm._s(_vm.dialog.topic))]
                          ),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "delete",
                            attrs: { "aria-label": "close" },
                            on: {
                              click: function($event) {
                                _vm.dialogVisible = false
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("section", { staticClass: "modal-card-body" }, [
                          _c("div", { staticClass: "box" }, [
                            _c("p", [_vm._v(_vm._s(_vm.dialog.message))]),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.email,
                                      expression: "email"
                                    }
                                  ],
                                  staticClass: "input is-large",
                                  attrs: {
                                    name: "email",
                                    id: "sup_email",
                                    type: "email",
                                    placeholder: _vm.jtre("Your email address")
                                  },
                                  domProps: { value: _vm.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.email = $event.target.value
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button is-primary",
                              class: { "is-loading": _vm.dialog.processing },
                              on: {
                                click: function($event) {
                                  return _vm.dialogOk()
                                }
                              }
                            },
                            [_c("Jtr", [_vm._v("Send")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function($event) {
                                  _vm.dialogVisible = false
                                  _vm.dialog.processing = false
                                }
                              }
                            },
                            [_c("Jtr", [_vm._v("Cancel")])],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bannerVisible
                  ? _c("div", { staticClass: "modal is-active" }, [
                      _c("div"),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-card" }, [
                        _c(
                          "header",
                          {
                            staticClass: "modal-card-head",
                            staticStyle: { background: "rgb(92,168,55,0.9)" }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "modal-card-title",
                                staticStyle: { color: "rgb(255,255,255,1)" },
                                attrs: { id: "sup_title" }
                              },
                              [
                                _c("Jtr", [
                                  _vm._v("We use cookies in Opetusaineistot")
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "section",
                          {
                            staticClass: "modal-card-body",
                            staticStyle: { background: "rgb(92,168,55,0.9)" }
                          },
                          [
                            _c("div", { staticClass: "box" }, [
                              _c(
                                "p",
                                [
                                  _c("Jtr", [
                                    _vm._v(
                                      "We use cookies to ensure that Opetusaineistot service is available.  Cookies are used in sessions and chat."
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _c("Jtr", [
                                    _vm._v(
                                      "All the cookies in Opetusaineistot are necessary for the system.  We use cookies only to ensure proper Opetusaineistot functionality."
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("br")
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button is-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.bannerOk()
                                  }
                                }
                              },
                              [
                                _c("Jtr", [
                                  _vm._v(
                                    "I understand and i accept the use of cookies"
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button is-gray",
                                on: {
                                  click: function($event) {
                                    return _vm.bannerCancel()
                                  }
                                }
                              },
                              [
                                _c("Jtr", [
                                  _vm._v("I do not accept the use of cookies")
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.reset != "no"
                  ? _c("div", { staticClass: "modal is-active" }, [
                      _c("div", { staticClass: "modal-background" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-card" }, [
                        _c("header", { staticClass: "modal-card-head" }, [
                          _c(
                            "p",
                            {
                              staticClass: "modal-card-title",
                              attrs: { id: "sup_title" }
                            },
                            [_c("Jtr", [_vm._v("Welcome to Opetusaineistot")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "delete",
                            attrs: { "aria-label": "close" },
                            on: {
                              click: function($event) {
                                _vm.reset = "no"
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("section", { staticClass: "modal-card-body" }, [
                          _c(
                            "div",
                            { staticClass: "box" },
                            [
                              _c("Message", {
                                attrs: {
                                  msg: _vm.reset_dialog.msg,
                                  color: "is-warning"
                                },
                                on: {
                                  "msg-finished": function($event) {
                                    _vm.reset_dialog.msg = ""
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _c("Jtr", [
                                    _vm._v(
                                      "One small step more: we wish you to set your password"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _c("Jtr", [
                                    _vm._v(
                                      "We sent to your email address your secret code.  Please enter it below with your new password"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "p",
                                    [
                                      _c("Jtr", [
                                        _vm._v("Your email address: ")
                                      ]),
                                      _vm._v(_vm._s(_vm.reset_dialog.username))
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reset_dialog.nonce,
                                        expression: "reset_dialog.nonce"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      name: "nonce",
                                      id: "res_nonce",
                                      type: "text",
                                      placeholder: _vm.jtre("Your secret code")
                                    },
                                    domProps: { value: _vm.reset_dialog.nonce },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reset_dialog,
                                          "nonce",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reset_dialog.password,
                                        expression: "reset_dialog.password"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      name: "password",
                                      id: "res_password",
                                      type: "password",
                                      placeholder: _vm.jtre("New password")
                                    },
                                    domProps: {
                                      value: _vm.reset_dialog.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reset_dialog,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reset_dialog.password_again,
                                        expression:
                                          "reset_dialog.password_again"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      name: "password_again",
                                      id: "res_password_again",
                                      type: "password",
                                      placeholder: _vm.jtre(
                                        "New password again"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.reset_dialog.password_again
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reset_dialog,
                                          "password_again",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button is-primary",
                              class: {
                                "is-loading": _vm.reset_dialog.processing
                              },
                              on: {
                                click: function($event) {
                                  return _vm.resetPassword()
                                }
                              }
                            },
                            [_c("Jtr", [_vm._v("Reset password")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function($event) {
                                  _vm.reset = "no"
                                  _vm.reset_dialog.processing = false
                                }
                              }
                            },
                            [_c("Jtr", [_vm._v("Cancel")])],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "avatar" }, [
      _c("img", {
        staticStyle: { "max-width": "137px", width: "100%" },
        attrs: { src: "/images/logo2.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "control", staticStyle: { "font-size": "x-small" } },
      [
        _c("a", { attrs: { href: "mailto:ehelpdesk@storia.fi" } }, [
          _vm._v("ehelpdesk@storia.fi")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }