<template>
  <div id="app">
<div class="hero is-success is-fullheight">
    <div class="hero-body">
        <div class="container has-text-centered" style="max-width: 500px;">
          <div class="columns is-vcentered">
            <div class="column">
            <div v-if="info != ''">
            <div id="info">
            <div class="notification is-warning is-light" style="color: black;">
            <button class="delete" v-on:click="info=''"></button>
            <strong>{{ topic }}</strong>
            <p></p>
            <div v-html="info"></div>
            <!-- {{ info }} -->
            </div>
            </div>
            </div>

                <div class="box circle">
                    <figure class="avatar">
                        <img src="/images/logo2.png" style="max-width:137px;width:100%">
                    </figure>
                    <h3 class="title has-text-black">Opetusaineistot</h3>

                    <div v-if="!gdprAccepted">
                      <div class="notification is-danger">
                        {{ gdprMsg }}
                      </div>
                      <p></p>
                      <button class="button is-primary" @click="reloadBanner()"><Jtr>Ask again</Jtr></button>
                    </div>

                    <form v-if="gdprAccepted" method="post">
                      <div class="field">
                          <div class="control has-icons-left">
                              <input name="username" class="input is-primary is-medium" type="text" v-bind:placeholder="username_string" autofocus v-model="email">
                              <span class="icon is-small is-left">
                                <font-awesome-icon icon="user" />&nbsp;
                              </span>
                          </div>
                          <p class="help is-primary"><Jtr>Please use either your email or username - firstname.lastname is not in use</Jtr></p>

                      </div>

                      <div class="field">
                          <div class="control has-icons-left">
                              <input name="password" class="input is-primary is-medium" type="password" v-bind:placeholder="password_string" v-model="password">
                              <span class="icon is-small is-left">
                                <font-awesome-icon icon="key" />&nbsp;
                              </span>
                          </div>
                      </div>
                      <hr class="is-lightgray" />
                      <div class="field is-grouped">
                        <div class="control">
                      <button name="login" type="submit" class="button is-block is-primary">
                        <font-awesome-icon icon="sign-in-alt" />&nbsp;
                        <Jtr>Sign in</Jtr>
                      </button>
                      </div>
                      <div class="control">
                      <a href="/v1/mpass/direct" class="button is-block is-info">
                        <font-awesome-icon icon="sign-in-alt" />&nbsp;
                        <Jtr>Sign in with MPASSid</Jtr>
                      </a>
                      </div>
                      </div>
                      <div class="field is-grouped">
                        <div class="control" style="font-size: x-small">
                          eHelpDesk: <Jtr>Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm</Jtr>
                        </div>
                        <div class="control" style="font-size: x-small">
                          <a href="mailto:ehelpdesk@storia.fi">ehelpdesk@storia.fi</a>
                        </div>
                      </div>
                    </form>
                </div>
                <p v-if="gdprAccepted" class="has-text-grey">
                  <a v-on:click="dialogOpen(1)"><Jtr>Register</Jtr></a> &nbsp;·&nbsp;
                  <a v-on:click="dialogOpen(2)"><Jtr>Forgot password?</Jtr></a>
                </p>
                <p class="has-text-grey">
                    <a href="/Opetusaineistot.kirjavalitys.fi tietosuojaseloste.pdf" target="_blank"><Jtr>GDPR</Jtr></a>
                    &nbsp;·&nbsp;
                    <a href="/Opetusaineistot-käyttöehdot.pdf" target="_blank"><Jtr>Terms of Use</Jtr></a>
                </p>
                <div v-if="msg != ''">
                    <div id="msg">
                        <div class="notification is-warning">
                        <button class="delete" v-on:click="msg=''"></button>
                        {{ msg }}
                        </div>
                    </div>
                </div>

                <div v-if="dialogVisible" class="modal is-active">
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                        <p id="sup_title" class="modal-card-title">{{ dialog.topic}}</p>
                        <button class="delete" aria-label="close" v-on:click="dialogVisible = false"></button>
                        </header>
                        <section class="modal-card-body">
                          <div class="box">
                            <p>{{ dialog.message }}</p><br>
                            <div class="field">
                              <div class="control">
                                  <input v-model="email" name="email" class="input is-large" id="sup_email" type="email" :placeholder="jtre('Your email address')">
                              </div>
                            </div>
                          </div>
                            <button v-bind:class="{ 'is-loading' : dialog.processing }" v-on:click="dialogOk()" class="button is-primary"><Jtr>Send</Jtr></button>
                            <button class="button is-light" v-on:click="dialogVisible = false; dialog.processing = false;"><Jtr>Cancel</Jtr></button>
                        </section>
                    </div>
                </div>

                <div v-if="bannerVisible" class="modal is-active">
                    <div> </div>
                    <div class="modal-card">
                        <header class="modal-card-head"  style="background: rgb(92,168,55,0.9);">
                        <p id="sup_title" class="modal-card-title" style="color: rgb(255,255,255,1);"><Jtr>We use cookies in Opetusaineistot</Jtr></p>
                        </header>
                        <section class="modal-card-body" style="background: rgb(92,168,55,0.9);">
                          <div class="box">
                            <p><Jtr>We use cookies to ensure that Opetusaineistot service is available.  Cookies are used in sessions and chat.</Jtr></p><br />
                            <p><Jtr>All the cookies in Opetusaineistot are necessary for the system.  We use cookies only to ensure proper Opetusaineistot functionality.</Jtr></p><br />
                          </div>
                          <button class="button is-primary" v-on:click="bannerOk()"><Jtr>I understand and i accept the use of cookies</Jtr></button>
                          <button class="button is-gray" v-on:click="bannerCancel()"><Jtr>I do not accept the use of cookies</Jtr></button>

                          <!-- <button class="button is-light" v-on:click="bannerCancel()"><Jtr>Reject cookies</Jtr></button> -->
                        </section>
                    </div>
                </div>

                <div v-if="reset != 'no'" class="modal is-active">
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                        <p id="sup_title" class="modal-card-title"><Jtr>Welcome to Opetusaineistot</Jtr></p>
                        <button class="delete" aria-label="close" v-on:click="reset = 'no'"></button>
                        </header>
                        <section class="modal-card-body">
                          <div class="box">
                            <Message v-bind:msg="reset_dialog.msg" v-bind:color="'is-warning'" v-on:msg-finished="reset_dialog.msg=''"></Message>
                            <p><Jtr>One small step more: we wish you to set your password</Jtr></p>
                            <p><Jtr>We sent to your email address your secret code.  Please enter it below with your new password</Jtr></p>
                            <div class="field">
                              <div class="control">
                                  <!-- <input v-model="reset_dialog.email" name="email" class="input" id="res_email" type="text"> -->
                                  <p><Jtr>Your email address: </Jtr>{{reset_dialog.username}}</p>
                              </div>
                            </div>
                            <div class="field">
                              <div class="control">
                                  <input v-model="reset_dialog.nonce" name="nonce" class="input" id="res_nonce" type="text" :placeholder="jtre('Your secret code')">
                              </div>
                            </div>
                            <div class="field">
                              <div class="control">
                                  <input v-model="reset_dialog.password" name="password" class="input" id="res_password" type="password" :placeholder="jtre('New password')">
                              </div>
                            </div>
                            <div class="field">
                              <div class="control">
                                  <input v-model="reset_dialog.password_again" name="password_again" class="input" id="res_password_again" type="password" :placeholder="jtre('New password again')">
                              </div>
                            </div>
                          </div>
                            <button v-bind:class="{ 'is-loading' : reset_dialog.processing }" v-on:click="resetPassword()" class="button is-primary"><Jtr>Reset password</Jtr></button>
                            <button class="button is-light" v-on:click="reset = 'no'; reset_dialog.processing = false;"><Jtr>Cancel</Jtr></button>
                        </section>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>

import axios from 'axios';

import Jtr from '../components/Jtr.vue';
import Jtre from '../mixins/Jtre.vue';
import Message from '../components/Message.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'app',
  components: {
    Jtr, Message, Footer
  },
  mixins : [Jtre],
  data() {
      return {
          username_string : this.jtre("Username"),
          password_string : this.jtre("Password"),
          email: twigParameters.username,
          password: "",
          dialogVisible: false,
          dialog: {
            topic : "",
            message : "",
            value : 0,
            processing : false
          },
          msg: twigParameters.msg,
          reset : twigParameters.reset,
          // topic: "Tervetuloa Opetusaineistot-palveluun",
          // info: "Myös Otavan opiskelijan maailman tunnukset toimivat täällä",
          topic : "",
          info : twigParameters.info,
          domain: twigGlobals.domain,
          reset_dialog : {
            msg : "",
            username : twigParameters.username,
            nonce : "",
            password : "",
            password_again : "",
            nonce_key : "",
            processing : false
          },
          bannerVisible : false,
          gdprAccepted : true,
          gdprMsg : ""
      };
  },
  mounted : function() {
    var cookie = this.$cookies.get("opetusaineistot-gdpr-yeah");
    if (cookie == null) {
      this.gdprAccepted = false;
      this.bannerVisible = true;
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      return;
    }
    (function(w, t, f) {
    var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;
    w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
    })(window,document,"ccf1c21c-635e-11ea-b9f6-0242ac110015");

    // (function(w, t, f) {var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live/';w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);})(window,document,5970);    
  },
  methods : {
    reloadBanner() {
      location = location; // Refresh
    },
    bannerOk() {
      this.bannerVisible = false;
      this.gdprAccepted = true;
      this.$cookies.set("opetusaineistot-gdpr-yeah", true);
      location = location;  // Strange window reload command
    },
    bannerCancel() {
      this.bannerVisible = false;
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      this.gdprMsg = this.jtre("Opetusaineistot is not functionable without cookies");
      // window.location.assign("/noaccess");
    },
    resetPassword() {
      // var url = this.domain + "/login/reset_password";
      var url = "/login/reset_password";
      var self = this;
      
      this.reset_dialog.processing = true;
      this.reset_dialog.nonce_key = this.reset;

      axios.post(url,this.reset_dialog)
      .then(function (response) {
        self.reset = 'no';
        self.reset_dialog.msg = '';
        self.msg = self.jtre("Password reset.  You can now login with your new password");
      })
      .catch(function (error) {
        if (error.response) {
          var state = error.response.data.status;
          switch (state) {
            case 'nonce':
              self.reset_dialog.msg = self.jtre("You typed wrong secret code - try again");
              break;
            case 'missing_password':
              self.reset_dialog.msg = self.jtre("Please type the same password to both fields");
              break;
            case 'no_match':
              self.reset_dialog.msg = self.jtre("Please type the same password to both fields");
              break;
            case 'too_short':
              self.reset_dialog.msg = self.jtre("Your password is too short");
              break;
            case 'too_long':
              self.reset_dialog.msg = self.jtre("Your password is too long");
              break;
            case 'easy_to_guess':
            case 'crackable_password':
              self.reset_dialog.msg = self.jtre("Your password may be cracable");
              break;
            case 'unknown':
              self.reset_dialog.msg = self.jtre("Unknown error");
              break;
            case 'oidc_server':
              self.reset_dialog.msg = self.jtre("Internal error - cannot change password");
              break;
            default:
              self.reset_dialog.msg = state;
              break;
          }
        } else {
          self.msg = reset_dialog.error;
        }
      })
      .then(function () {
        self.reset_dialog.processing = false;
      });
    },
    dialogOpen(value) {
      if (value == 1) {
          // this.dialogTopic="Register";
          this.dialog.topic = this.jtre('Register');
          this.dialog.message= this.jtre('We will send you a registration link via email');
          this.dialog.value = value;
      } else if (value == 2) {
          this.dialog.topic = this.jtre('Restore password');
          this.dialog.message = this.jtre('We will send you change password link via email');
          this.dialog.value = value;
      }
      this.dialogVisible = true;
    },
    dialogOk() {
      this.dialog.processing = true;

      // var url = this.domain;
      var url = "";

      if (this.dialog.value == 1) {
        url = url + "/fetch_signup";
      } else if (this.dialog.value == 2) {
        url = url + "/fetch_password";
      } else {
        return; // No match
      }

      var self = this;
      axios.post(url, { email : this.email})
      .then (function(response) {
        self.dialog.processing = false;
        self.dialogVisible = false;
        // self.msg = response.data.msg + " " + response.data.link;
        self.msg = self.jtre("We have emailed you a link ") + response.data.link;
      })
      .catch (function(error) {
        self.dialog.processing = false;
        self.dialogVisible = false;

        if (error.response) {
          self.msg = error.response.data.error;
        } else {
          self.msg = error;
        }
      });
    }
  }
};
</script>
<style lang="scss">

@charset 'UTF-8';
$kv-blue: rgb(0,63,133);
$kv-green: rgb(188, 207, 65);
$kv-red: rgb(207, 65, 65);
$kv-gray: rgb(128, 128, 128);
$kv-light-gray: rgb(168, 168, 168);
$kv-dark-gray: rgb(87, 87, 87);
$kv-white: rgb(255, 255, 255);
$kv-close: rgba(0, 0, 0, 0);
$kv-modal: rgba(27, 27, 27, 0.9);
$kv-white2: ivory;
$kv-black: rgb(0, 0, 0);
$hero-background: rgb(240,250,255);

$theme-colors: (
  "primary": $kv-blue,
  "danger": $kv-red,
);

$input-placeholder-color: $kv-blue;
$primary: $kv-blue;

@import '~bulma';

html,body {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.hero.is-success {
  background: $hero-background;
}
.hero .nav, .hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box.circle {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr{
  border-bottom: 1px solid black;
}

.has-text-black{
  color: black;
}

.field{
  padding-bottom: 10px;
}

.fa{
  margin-left: 5px; 
}

hr.is-lightgray {
  background : lightgray
}

</style>

