var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "footer" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c(
            "div",
            { staticClass: "columns", staticStyle: { "font-size": "small" } },
            [
              _c("div", { staticClass: "column" }, [
                _vm._v("\n                    © "),
                _c("a", { attrs: { href: "https://www.kirjavalitys.fi" } }, [
                  _vm._v("Kirjavälitys Oy")
                ]),
                _vm._v(" 2015 - " + _vm._s(_vm.year) + "\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/Opetusaineistot.kirjavalitys.fi tietosuojaseloste.pdf",
                      target: "_blank"
                    }
                  },
                  [_c("Jtr", [_vm._v("GDPR")])],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-half" },
                [
                  _vm._v("\n                    eHelpDesk: "),
                  _c("Jtr", [
                    _vm._v("Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("a", { attrs: { href: "mailto:ehelpdesk@storia.fi" } }, [
        _vm._v("ehelpdesk@storia.fi")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }