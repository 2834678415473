var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "message" } }, [
    _vm.msg != ""
      ? _c("div", { staticClass: "notification", class: _vm.color }, [
          _c("button", {
            staticClass: "delete",
            on: {
              click: function($event) {
                return _vm.$emit("msg-finished")
              }
            }
          }),
          _vm._v("\n        " + _vm._s(_vm.msg) + "\n    ")
        ])
      : _c(
          "div",
          {
            staticClass: "notification",
            staticStyle: { background: "rgba(0,0,0,0)" }
          },
          [_vm._v("\n         \n    ")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }