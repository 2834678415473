import Vue from 'vue'
import App from './Login.vue'

require('es6-promise').polyfill();

import { faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faKey} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSignInAlt)
library.add(faUser)
library.add(faKey)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(require('vue-cookies'))
Vue.$cookies.config('180d')

new Vue({
  render: h => h(App),
}).$mount('#app')
// Comment
