<template>
    <span id="jtr"> {{ result }} </span>
</template>

<script>

import axios from 'axios';
import JtrWords from '../translations/jtr_home.js';

export default {
  name : "Jtr",
  data: function() {
      return {
          result : ""
      }
  },
  created: function() {
      var self = this;
        var text = self.$slots.default[0].text;

      for (var i=0; i < JtrWords.length; i++) {
        if (JtrWords[i].id == text) {
            this.result = JtrWords[i].fi;
            return;
        }
    }
    this.result = text;

    //   }
    //   this.result = JtrWords.find(function(x) { return x.id == self.$slots.default[0].text; }).fi;
    //   var jsonString = window.sessionStorage.getItem("JtrWords");
    //   if (jsonString == null) {
    //       this.loadTranslations();
    //   } else {      
    //     var jtrArray = JSON.parse(jsonString);
    //     var text = this.$slots.default[0].text;
    //     for (var i = 0; i < jtrArray.length; i++) {
    //         if (jtrArray[i].id == text) {
    //             this.result = jtrArray[i].fi;
    //             return;
    //         }
    //     }
    //     this.result = text;
    // }
},
  updated: function() {
  },
  methods: {
      loadTranslations() {
          var jsonString = JSON.stringify(JtrWords);
          window.sessionStorage.setItem("JtrWords",jsonString);
      }
  }
}
</script>
