<template>
    <div id="message">
        <div v-if="msg != ''" class="notification" v-bind:class="color">
            <button class="delete" v-on:click="$emit('msg-finished')"></button>
            {{ msg }}
        </div>
        <div v-else class="notification" style="background: rgba(0,0,0,0);">
            &nbsp;
        </div>
    </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    msg : String,
    color : String
  },
  components: {
  },
  data: function() {
      return {
      }
  },
  methods: {
  },

  watch: {
  }
}
</script>
