export default [
    {
        id : "Here are the teachers' materials for your school.  You can select your favorites that will be on top when  you log in.  You can update your favorites any time by clicking the heart next to the prduct.",
        fi : "Tässä ovat koulullesi tilatut opettajan materiaalit.  Voit valita näistä suosikkisi, jotka näkyvät kirjautuessasi tuotelistan alussa.  Voit päivittää suosikkejasi klikkaamalla tuotteen yhteydessä olevaa sydäntä",
        se : ""
    },
    {
        id : "Welcome to Opetusaineistot!",
        fi : "Tervetuloa Opetusaineistot-palveluun!",
        se : ""
    },
    {
        id : "Ready",
        fi : "Valmis",
        se : ""
    },
    {
        id : "Select school",
        fi : "Valitse koulu",
        se : ""
    },
    {
        id : "Activate license",
        fi : "Aktivoi lisenssi",
        se : ""
    },
    {
        id : "Add students",
        fi : "Lisää oppilaita",
        se : ""
    },
    {
        id : "Licenses",
        fi : "Lisenssit",
        se : ""
    },
    {
        id : "+ Join School",
        fi : "+ Liity kouluun",
        se : ""
    },
    {
        id : "School students",
        fi : "Koulun oppilaat",
        se : ""
    },
    {
        id : "Schools",
        fi : "Koulut",
        se : ""
    },
    {
        id : "Students",
        fi : "Oppilaat",
        se : ""
    },
    {
        id : "Personal info",
        fi : "Omat tiedot",
        se : ""
    },
    {
        id : "Give feedback",
        fi : "Anna palautetta",
        se : ""
    },
    {
        id : "Sign out",
        fi : "Kirjaudu ulos",
        se : ""
    },
    {
        id : "Please give school password:",
        fi : "Anna koulun salasana:",
        se : ""
    },
    {
        id : "You can get the school password from your school secretary",
        fi : "Saat koulun salasanan koululta",
        se : ""
    },
    {
        id : "Send feedback to the developers",
        fi : "Anna kehittäjille palautetta",
        se : ""
    },
    {
        id : "Join school",
        fi : "Liity kouluun",
        se : ""
    },
    {
        id : "Please send feedback to the Opetusaineistot site developers",
        fi : "Tässä on tilaisuutesi antaa palautetta Opetusaineistot-palvelun kehittäjille",
        se : ""
    },
    {
        id : "Send!",
        fi : "Lähetä!",
        se : ""
    }
   ,{
      id : "Activate!",
      fi : "Aktivoi!",
      se : ""
   }
   ,{
      id : "Add product",
      fi : "Lisää tuote",
      se : ""
   }
   ,{
      id : "Add product to school",
      fi : "Lisää tuote koululle",
      se : ""
   }
   ,{
      id : "Add product to school. School: ",
      fi : "Lisää tuote koululle. Koulu: ",
      se : ""
   }
   ,{
      id : "Add to users",
      fi : "Lisää käyttäjille",
      se : ""
   }
   ,{
      id : "Alrady registered? Sign in",
      fi : "Oletko jo rekisteröitynyt? Kirjaudu tästä",
      se : ""
   }
   ,{
      id : "Cancel",
      fi : "Peru",
      se : ""
   }
   ,{
      id : "Continue...",
      fi : "Jatka...",
      se : ""
   }
   ,{
      id : "Filter",
      fi : "Suodata",
      se : ""
   }
   ,{
      id : "Forgot password?",
      fi : "Unohdin salasanani",
      se : ""
   }
   ,{
      id : "GDPR",
      fi : "Tietosuojaseloste",
      se : ""
   }
   ,{
      id : "I'm a student",
      fi : "Olen opiskelija",
      se : ""
   }
   ,{
      id : "I'm a teacher",
      fi : "Olen opettaja",
      se : ""
   }
   ,{
      id : "License to users",
      fi : "Käyttäjien lisenssi",
      se : ""
   }
   ,{
      id : "Loading...",
      fi : "Lataa...",
      se : ""
   }
   ,{
      id : "MPASSid usage",
      fi : "MPASSid:n käyttö",
      se : ""
   }
   ,{
      id : "Manage licenses",
      fi : "Hallinnoi lisenssejä",
      se : ""
   }
   ,{
      id : "Next",
      fi : "Seuraava",
      se : ""
   }
   ,{
      id : "Please give the activation key",
      fi : "Anna aktivointiavain",
      se : ""
   }
   ,{
      id : "Please give your username and password and press 'Link'. You can press 'Don't link' if you don't want to link the accounts",
      fi : "Anna käyttäjätunnuksesi ja salasanasi ja paina 'Linkitä'. Voit painaa 'Älä linkitä', jos et halua yhdistää tunnuksia",
      se : ""
   }
   ,{
      id : "Please update user information.  Leave the password field empty if you do not want to change user's password",
      fi : "Päivitä käyttäjän tiedot.  Jätä salasanakenttä tyhjäksi, jos et halua vaihtaa salasanaa",
      se : ""
   }
   ,{
      id : "Previous",
      fi : "Edellinen",
      se : ""
   }
   ,{
      id : "Read student info from a file",
      fi : "Lue opiskelijoiden tiedot tiedostosta",
      se : ""
   }
   ,{
      id : "Register",
      fi : "Rekisteröidy",
      se : ""
   }
   ,{
      id : "Register to Opetusaineistot",
      fi : "Rekisteröidy Opetusaineistoihin",
      se : ""
   }
   ,{
      id : "Save changes",
      fi : "Tallenna muutokset",
      se : ""
   }
   ,{
      id : "School data",
      fi : "Koulun tiedot",
      se : ""
   }
   ,{
      id : "School license",
      fi : "Koululisenssi",
      se : ""
   }
   ,{
      id : "Select file",
      fi : "Valitse tiedosto",
      se : ""
   }
   ,{
      id : "Send",
      fi : "Lähetä",
      se : ""
   }
   ,{
      id : "Sign in",
      fi : "Kirjaudu sisälle",
      se : ""
   }
   ,{
      id : "Sign in with MPASSid",
      fi : "Kirjaudu MPASSid:lla",
      se : ""
   }
   ,{
      id : "This is your first time to use MPASSid in Opetusaineistot.  If you already have an account, you can combine it with your MPASSid account and see your licenses also via MPASSid.",
      fi : "Tämä on ensimmäinen kerta MPASSid:lla Opetusaineistoissa.  Jos sinulla on jo Opetusaineistoissa tunnus, voit yhdistää sen MPASSid:n kanssa ja näet molemmilla tunnuksilla samat lisenssit.",
      se : ""
   }
   ,{
      id : "Update",
      fi : "Päivitä",
      se : ""
   }
   ,{
      id : "Update changes",
      fi : "Päivitä muutokset",
      se : ""
   }
   ,{
      id : "Update data",
      fi : "Päivitä tiedot",
      se : ""
   }
   ,{
      id : "Update user data",
      fi : "Päivitä käyttäjän tiedot",
      se : ""
   }
   ,{
      id : "You are about to activate the following product",
      fi : "Olet aktivoimassa seuraavan tuotteen",
      se : ""
   }
   ,{
      id : "You are about to modify access to the following license:",
      fi : "Olet muokkaamassa seuraavan tuotteen saatavuutta:",
      se : ""
   }
   ,{
      id : "You are giving / taking the following license for students.",
      fi : "Olet antamassa / poistamassa seuraavaa tuotetta.",
      se : ""
   }
   ,{
      id : "Your school secretary can give you the school password",
      fi : "Saat koulun salasanan koululta",
      se : ""
   },
   {
       id : "Username",
       fi : "Käyttäjätunnus",
       se : ""
   },
   {
       id : "Password",
       fi : "Salasana",
       se : ""
   },
   {
    id : "Activation key",
    fi : "Aktivointiavain",
    se : ""
   },
   {
      id : "Update done!",
      fi : "Päivitetty!",
      se : ""
   }
   ,{
      id : " months",
      fi : " kk",
      se : ""
   }
   ,{
      id : "Class",
      fi : "Luokka",
      se : ""
   }
   ,{
      id : "Current class (e.g. 9B)",
      fi : "Nykyinen luokka (esim. 9B)",
      se : ""
   }
   ,{
      id : "Family name",
      fi : "Sukunimi",
      se : ""
   }
   ,{
      id : "First name(s)",
      fi : "Etunimet",
      se : ""
   }
   ,{
      id : "First names",
      fi : "Etunimet",
      se : ""
   }
   ,{
      id : "Last name",
      fi : "Sukunimi",
      se : ""
   }
   ,{
      id : "Local password",
      fi : "Salasana",
      se : ""
   }
   ,{
      id : "Password again",
      fi : "Salasana uudestaan",
      se : ""
   }
   ,{
      id : "Role",
      fi : "Rooli",
      se : ""
   }
   ,{
      id : "School role",
      fi : "Rooli",
      se : ""
   }
   ,{
      id : "Amount",
      fi : "Määrä",
      se : ""
   }
   ,{
      id : "Continue with the unsaved students",
      fi : "Jatka tallentamattomien oppilaiden käsittelyä",
      se : ""
   }
   ,{
      id : "Data is loaded!",
      fi : "Tiedot on ladattu!",
      se : ""
   }
   ,{
      id : "Data is saved!",
      fi : "Tiedot on talletettu!",
      se : ""
   }
   ,{
      id : "Delete line",
      fi : "Poista rivi",
      se : ""
   }
   ,{
      id : "Feedback",
      fi : "Palaute",
      se : ""
   }
   ,{
      id : "Filter...",
      fi : "Suodata...",
      se : ""
   }
   ,{
      id : "First name",
      fi : "Etunimi",
      se : ""
   }
   ,{
      id : "Give class",
      fi : "Anna luokka",
      se : ""
   }
   ,{
      id : "Give first names",
      fi : "Anna etunimesi",
      se : ""
   }
   ,{
      id : "Give last name",
      fi : "Anna sukunimesi",
      se : ""
   }
   ,{
      id : "Give password",
      fi : "Anna salasana",
      se : ""
   }
   ,{
      id : "Give username",
      fi : "Anna käyttäjätunnus",
      se : ""
   }
   ,{
      id : "Insert line",
      fi : "Lisää rivi",
      se : ""
   }
   ,{
      id : "Insert student data from a file",
      fi : "Lisää opiskelijoita tiedostosta",
      se : ""
   }
   ,{
      id : "Isbn",
      fi : "Isbn",
      se : ""
   }
   ,{
      id : "Municipality",
      fi : "Kunta",
      se : ""
   }
   ,{
      id : "Name",
      fi : "Nimi",
      se : ""
   }
   ,{
      id : "Password, at least 10 characters",
      fi : "Vähintään 10 merkin salasana",
      se : ""
   }
   ,{
      id : "Save valid students to a CSV file",
      fi : "Säästä tallennetut opiskelijatiedot CSV-tiedostoon",
      se : ""
   }
   ,{
      id : "School code",
      fi : "Koulukoodi",
      se : ""
   }
   ,{
      id : "School group name",
      fi : "Kouluryhmän nimi",
      se : ""
   }
   ,{
      id : "School group password",
      fi : "Kouluryhmän salasana",
      se : ""
   }
   ,{
      id : "School group username",
      fi : "Kouluryhmän käyttäjätunnus",
      se : ""
   }
   ,{
      id : "School password",
      fi : "Koulun salasana",
      se : ""
   }
   ,{
      id : "Search...",
      fi : "Etsi...",
      se : ""
   }
   ,{
      id : "Student's class (e.g. 9B)",
      fi : "Luokka (esim. 9B)",
      se : ""
   }
   ,{
      id : "Student's family name",
      fi : "Oppilaan sukunimi",
      se : ""
   }
   ,{
      id : "Student's first name(s)",
      fi : "Oppilaan etunimet",
      se : ""
   }
   ,{
      id : "Update missing usernames and passwords",
      fi : "Lisää puuttuvat käyttäjätunnukset ja salasanat",
      se : ""
   }
   ,{
      id : "Username, at least six characters",
      fi : "Vähintään kuuden merkin käyttäjätunnus",
      se : ""
   }
   ,{
      id : "Your email address",
      fi : "Sähköpostiosoitteesi",
      se : ""
   }
   ,{
      id : "Your password",
      fi : "Salasanasi",
      se : ""
   }
   ,{
      id : "Your username",
      fi : "Käyttäjätunnuksesi",
      se : ""
   }
   ,{
      id : "Restore password",
      fi : "Palauta salasana",
      se : ""
   }
   ,{
      id : "We will send you a registration link via email",
      fi : "Lähetämme sinulle rekisteröintilinkin sähköpostitse",
      se : ""
   }
   ,{
      id : "We will send you change password link via email",
      fi : "Lähetämme sinulle salasanan vaihtolinkin sähköpostitse",
      se : ""
   }
   ,{
      id : "Info",
      fi : "Tiedot",
      se : ""
   }
   ,{
      id : "School",
      fi : "Koulu",
      se : ""
   }
   ,{
      id : "School info",
      fi : "Koulun tiedot",
      se : ""
   }
   ,{
      id : "School name",
      fi : "Koulun nimi",
      se : ""
   }
   ,{
      id : "School secret",
      fi : "Koulun salasana",
      se : ""
   }   
   ,{
      id : "Add a substitute teacher",
      fi : "Lisää sijaisopettaja",
      se : ""
   }
   ,{
      id : "Add teacher",
      fi : "Lisää opettaja",
      se : ""
   }
   ,{
      id : "Cancel teacher status",
      fi : "Poista opettaja",
      se : ""
   }
   ,{
      id : "Cancel teacher status!",
      fi : "Poista opettaja!",
      se : ""
   }
   ,{
      id : "Delete teacher",
      fi : "Poista opettaja",
      se : ""
   }
   ,{
      id : "Email",
      fi : "Sähköposti",
      se : ""
   }
   ,{
      id : "Generate username and password",
      fi : "Generoi käyttäjätunnus ja salasana",
      se : ""
   }
   ,{
      id : "Please give substitute teacher info here.",
      fi : "Anna sijaisopettajan tiedot.",
      se : ""
   }
   ,{
      id : "Please update school info here.  Leave the group password field empty if you want to keep the current group password",
      fi : "Päivitä koulun tiedot tässä.  Jätä ryhmän salasana tyhjäksi, jos et halua vaihtaa sitä",
      se : ""
   }
   ,{
      id : "School info saved!",
      fi : "Koulun tiedot on päivitetty!",
      se : ""
   }
   ,{
      id : "Substitute teacher created!",
      fi : "Sijaisopettaja on lisätty kouluun!",
      se : ""
   }
   ,{
      id : "Teacher removed from school!",
      fi : "Opettaja on poistettu koulusta!",
      se : ""
   }
   ,{
      id : "Teachers",
      fi : "Opettajat",
      se : ""
   }
   ,{
      id : "Update school info",
      fi : "Päivitä koulun tiedot",
      se : ""
   }
   ,{
      id : "You are about to remove the following teacher from school. Are you sure?",
      fi : "Olet poistamassa seuraavaa opettajaa koulusta. Oletko varma?",
      se : ""
   }
   ,{
      id : "teacher",
      fi : "Opettaja",
      se : ""
   }
   ,{
      id : "substitute",
      fi : "Sijainen",
      se : ""
   }
   ,{
      id : "Change password",
      fi : "Vaihda salasana",
      se : ""
   }
   ,{
      id : "Change password for Opetusaineistot",
      fi : "Vaihda Opetusaineistot-palvelun salasana",
      se : ""
   }
   ,{
      id : "Don't change",
      fi : "Älä vaihda",
      se : ""
   }
   ,{
      id : "New password",
      fi : "Uusi salasana",
      se : ""
   }
   ,{
      id : "New password again",
      fi : "Uusi salasana uudestaan",
      se : ""
   }
   ,{
      id : "User prefix",
      fi : "Käyttäjätunnuksen alkuosa",
      se : ""
   }
   ,{
      id : "Your password is too short",
      fi : "Salasanasi on liian lyhyt",
      se : ""
   }
   ,{
      id : "Your passwords need to be equal",
      fi : "Anna sama salasana kahteen kertaan",
      se : ""
   }
   ,{
      id : "Update failed. Username is already reserved",
      fi : "Päivitys epäonnistui.  Käyttäjätunnus on varattu",
      se : ""
   }
   ,{
      id : "This is a school license for everyone. Have fun.",
      fi : "Tämä on koululisenssi. Käytä vapaasti.",
      se : ""
   }
   ,{
      id : "All licenses are in use",
      fi : "Kaikki lisenssit ovat käytössä",
      se : ""
   }
   ,{
      id : "Available licenses",
      fi : "Saatavilla olevat lisenssit",
      se : ""
   }
   ,{
      id : "Product",
      fi : "Tuote",
      se : ""
   }
   ,{
      id : "Subject",
      fi : "Aine",
      se : ""
   }
   ,{
      id : "You have ",
      fi : "Sinulla on ",
      se : ""
   }
   ,{
      id : " licenses",
      fi : " lisenssiä jaettavissa",
      se : ""
   }
   ,{
      id : "Bad school password",
      fi : "Väärä koulun salasana",
      se : ""
   }
   ,{
      id : "Feedback sent!",
      fi : "Palaute lähetetty!",
      se : ""
   }
   ,{
      id : "Join school (requires school password)",
      fi : "Liity kouluun (tarvitset koulun salasanan)",
      se : ""
   }
   ,{
      id : "Join school succeeded!",
      fi : "Kouluun liittyminen onnistui!",
      se : ""
   }
   ,{
      id : "Memberships",
      fi : "Jäsenyydet",
      se : ""
   }
   ,{
      id : "You can change your personal info via Kirjavälitys identity server: ",
      fi : "Voit vaihtaa henkilökohtaiset tietosi Kirjavälityksen tunnistuspalvelussa: ",
      se : ""
   }
   ,{
      id : "admin",
      fi : "Ylläpitäjä",
      se : ""
   }
   ,{
      id : "publisher",
      fi : "Kustantaja",
      se : ""
   }
   ,{
      id : "high",
      fi : "Yksityiskäyttäjä",
      se : ""
   }
   ,{
      id : "elementary",
      fi : "Oppilas",
      se : ""
   }
   ,{
      id : "autogroup",
      fi : "Kouluryhmä",
      se : ""
   }
   ,{
      id : "Add substitute teacher",
      fi : "Lisää sijaisopettaja",
      se : ""
   }
   ,{
      id : "Leave the password field empty if you do not want to change user's password",
      fi : "Jätä salasanakenttä tyhjäksi, jos et halua vaihtaa käyttäjän salasanaa",
      se : ""
   }
   ,{
      id : "Substitute teacher",
      fi : "Sijaisopettaja",
      se : ""
   }
   ,{
      id : "Update teacher info",
      fi : "Päivitä opettajan tiedot",
      se : ""
   }
   ,{
      id : "Substitute teacher info updated!",
      fi : "Sijaisopettajan tiedot on päivitetty",
      se : ""
   }
   ,{
      id : "Username is not available",
      fi : "Käyttäjätunnus on varattu",
      se : ""
   }
   ,{
      id : "Add a license",
      fi : "Lisää lisenssi",
      se : ""
   }
   ,{
      id : "Add license to schools",
      fi : "Lisää lisenssi kouluille",
      se : ""
   }
   ,{
      id : "Add licenses to the school",
      fi : "Lisää lisenssi koululle",
      se : ""
   }
   ,{
      id : "Add product to a school",
      fi : "Lisää tuote koululle",
      se : ""
   }
   ,{
      id : "Audience",
      fi : "Kohderyhmä",
      se : ""
   }
   ,{
      id : "Available",
      fi : "Saatavuus",
      se : ""
   }
   ,{
      id : "Back to school listing",
      fi : "Koululistaukseen",
      se : ""
   }
   ,{
      id : "Category",
      fi : "Luokka",
      se : ""
   }
   ,{
      id : "From",
      fi : "Alkaen",
      se : ""
   }
   ,{
      id : "ISBN",
      fi : "ISBN",
      se : ""
   }
   ,{
      id : "ISBN:s",
      fi : "ISBN:t",
      se : ""
   }
   ,{
      id : "In use",
      fi : "Käytössä",
      se : ""
   }
   ,{
      id : "License type",
      fi : "Lisenssityyppi",
      se : ""
   }
   ,{
      id : "Licenses",
      fi : "Lisenssit",
      se : ""
   }
   ,{
      id : "Licenses added to school!",
      fi : "Lisenssit on lisätty koululle!",
      se : ""
   }
   ,{
      id : "Licenses added to schools!",
      fi : "Lisenssi on lisätty kouluille",
      se : ""
   }
   ,{
      id : "Missing product",
      fi : "Puuttuva tuote",
      se : ""
   }
   ,{
      id : "Not all licenses were added. Bad ISBN:s: ",
      fi : "Osa ISBN:sta oli virheellisiä: ",
      se : ""
   }
   ,{
      id : "Not all licenses were added. Bad school id(s): ",
      fi : "Osa koulutunnuksista oli virheellisiä: ",
      se : ""
   }
   ,{
      id : "Number of licenses",
      fi : "Lisenssien määrä",
      se : ""
   }
   ,{
      id : "Oid",
      fi : "Koulukoodi",
      se : ""
   }
   ,{
      id : "Oids",
      fi : "Koulukoodit",
      se : ""
   }
   ,{
      id : "Orders",
      fi : "Tilaukset",
      se : ""
   }
   ,{
      id : "Org id",
      fi : "Koulukoodi",
      se : ""
   }
   ,{
      id : "Organization id",
      fi : "Koulukoodi",
      se : ""
   }
   ,{
      id : "Organization ids (separate with commas)",
      fi : "Koulukoodit (erota pilkuilla)",
      se : ""
   }
   ,{
      id : "Product ISBN:s (separate with commas)",
      fi : "ISBN-koodit (erota pilkuilla)",
      se : ""
   }
   ,{
      id : "Product name",
      fi : "Nimi",
      se : ""
   }
   ,{
      id : "Product title",
      fi : "Nimi",
      se : ""
   }
   ,{
      id : "Products",
      fi : "Tuotteet",
      se : ""
   }
   ,{
      id : "School id",
      fi : "Koulukoodi",
      se : ""
   }
   ,{
      id : "School licenses",
      fi : "Koulun lisenssit",
      se : ""
   }
   ,{
      id : "Teacher licenses updated!",
      fi : "Opettajien lisenssit on päivitetty!",
      se : ""
   }
   ,{
      id : "Title",
      fi : "Otsikko",
      se : ""
   }
   ,{
      id : "To",
      fi : "Päättyen",
      se : ""
   }
   ,{
      id : "Type",
      fi : "Tyyppi",
      se : ""
   }
   ,{
      id : "Update teacher licenses",
      fi : "Päivitä opettajien lisenssit",
      se : ""
   }
   ,{
      id : "Used",
      fi : "Käytetty",
      se : ""
   }
   ,{
      id : "Valid from",
      fi : "Voimassa alkaen",
      se : ""
   }
   ,{
      id : "Valid to",
      fi : "Voimassa päättyen",
      se : ""
   }
   ,{
      id : "student",
      fi : "Oppilas",
      se : ""
   }
   ,{
      id : "floating",
      fi : "Koululisenssi",
      se : ""
   }
   ,{
      id : "fixed",
      fi : "Henkilökohtainen",
      se : ""
   }
   ,{
      id : "{{ license.license_type }}",
      fi : "",
      se : ""
   }
   ,{
      id : "{{ teacher.role}}",
      fi : "",
      se : ""
   }
   ,{
      id : "{{membership.role}}",
      fi : "",
      se : ""
   }
   ,{
      id : "Save failed!",
      fi : "Tallennus epäonnistui!",
      se : ""
   }
   ,{
      id : "Save was incomplete. Some students were not saved.",
      fi : "Tallennus onnistui osittain.  Kaikkia oppilaita ei tallennettu.",
      se : ""
   }
   ,{
      id : "Save was successful!",
      fi : "Tallennus onnistui!",
      se : ""
   }
   ,{
      id : "Delete student",
      fi : "Poista oppilas",
      se : ""
   }
   ,{
      id : "Delete user",
      fi : "Poista käyttäjä",
      se : ""
   }
   ,{
      id : "Delete!",
      fi : "Poista!",
      se : ""
   }
   ,{
      id : "Don't delete",
      fi : "Älä poista",
      se : ""
   }
   ,{
      id : "Please update student information.  Leave the password field empty if you do not want to change the password",
      fi : "Päivitä oppilaan tiedot tässä.  Jätä salasanakenttä tyhjäksi, jos et halua vaihtaa salasanaa",
      se : ""
   }
   ,{
      id : "Student deleted!",
      fi : "Oppilas on poistettu!",
      se : ""
   }
   ,{
      id : "Update student info",
      fi : "Päivitä oppilaan tiedot",
      se : ""
   }
   ,{
      id : "Update user",
      fi : "Päivitä  käyttäjä",
      se : ""
   }
   ,{
      id : "You are about to delete the following student. Are you sure?",
      fi : "Olet aikeissa poistaa seuraavan oppilaan. Oletko varma?",
      se : ""
   }
   ,{
      id : "Remove membership",
      fi : "Poista jäsenyys",
      se : ""
   }
   ,{
      id : "Remove membership done!",
      fi : "Jäsenyys poistettu!",
      se : ""
   }
   ,{
      id : "Remove membership!",
      fi : "Poista jäsenyys!",
      se : ""
   }
   ,{
      id : "School group licenses updated!",
      fi : "Koulun ryhmälisenssit on päivitetty!",
      se : ""
   }
   ,{
      id : "Update school group licenses",
      fi : "Päivitä koulun ryhmälisenssit",
      se : ""
   }
   ,{
      id : "You are about to remove the following membership. Are you sure?",
      fi : "Olet poistamassa seuraavaa jäsenyyttä. Oletko varma?",
      se : ""
   }
   ,{
      id : "Only local users may be updated here",
      fi : "Vain paikallisten käyttäjien tietoja voi muokata täällä",
      se : ""
   }
   ,{
      id : "Family Name",
      fi : "Sukunimi",
      se : ""
   }
   ,{
      id : "Given Name",
      fi : "Etunimet",
      se : ""
   }
   ,{
      id : "Join school as admin",
      fi : "Liity kouluun ylläpitäjänä",
      se : ""
   }
   ,{
      id : "Please give user info here.",
      fi : "Anna tässä käyttäjän tiedot.",
      se : ""
   }
   ,{
      id : "School member",
      fi : "Käyttäjä",
      se : ""
   }
   ,{
      id : "Add student",
      fi : "Lisää oppilas",
      se : ""
   },
   {
      id : "Licenses added to school (press refresh)!",
      fi : "Lisenssit on lisätty koululle (lataa sivu uudestaan)",
      se : ""
   },
   {
      id : "You are about to remove the following member from school. Are you sure?",
      fi : "Olet poistamassa seuraavaa henkilöä koulusta. Oletko aivan varma tästä?",
      se : ""
   }
   ,{
      id : "Cancel membership",
      fi : "Poista henkilö koulusta",
      se : ""
   }
   ,{
      id : "Cancel membership!",
      fi : "Poista!",
      se : ""
   },
   {
      id : "You are about to remove the following student from school. Are you sure?",
      fi : "Olet poistamassa seuraavaa oppilasta koulusta. Oletko aivan varma tästä?",
      se : ""
   }
   ,{
      id : "Student removed from school!",
      fi : "Oppilas on poistettu koulusta!",
      se : ""
   }
   ,{
      id : "Leave the password field empty if you do not want to change student's password",
      fi : "Jätä salasanakenttä tyhjäksi, jos et halua muuttaa oppilaan salasanaa",
      se : ""
   }
   ,{
      id : "Please give student info here.",
      fi : "Anna oppilaan tiedot.",
      se : ""
   }
   ,{
      id : "School student",
      fi : "Koulun oppilas",
      se : ""
   }
   ,{
      id : "Student created!",
      fi : "Oppilas on lisätty kouluun!",
      se : ""
   }
   ,{
      id : "Student info updated!",
      fi : "Oppilaan tiedot on muutettu!",
      se : ""
   }
   ,{
      id : "You probably won't need personal student accounts.  The school group account is fine in most cases.",
      fi : "Et luultavasti tarvitse oppilaskohtaisia käyttäjätunnuksia.  Koulusi kouluryhmä riittää useimmissa tapauksissa.",
      se : ""
   }
   // twig/vue/login/Login.vue <p><Jtr>One small step more: we wish you to set your password</Jtr></p>
   ,{
      id : "One small step more: we wish you to set your password",
      fi : "Yksi pikku juttu vielä: sinun pitäisi asettaa salasana",
      se : ""
   }
   // twig/vue/login/Login.vue self.msg = self.jtre("Password reset.  You can now login with your new password");
   ,{
      id : "Password reset.  You can now login with your new password",
      fi : "Salasanan asetus onnistui. Voit nyt kirjautua järjestelmään",
      se : ""
   }
   // twig/vue/login/Login.vue <button v-bind:class="{ 'is-loading' : reset_dialog.processing }" v-on:click="resetPassword()" class="button is-primary"><Jtr>Reset password</Jtr></button>
   ,{
      id : "Reset password",
      fi : "Aseta salasana",
      se : ""
   }
   // twig/vue/components/NavbarOld.vue <a v-if="member.role == 'teacher'" href="/student_licenses" class="navbar-item"><Jtr>Student licenses</Jtr></a>
   ,{
      id : "Student licenses",
      fi : "Opiskelijan lisenssit",
      se : ""
   }
   // twig/vue/login/Login.vue <p><Jtr>We sent to your email address your secret code.  Please enter it below with your new password</Jtr></p>
   ,{
      id : "We sent to your email address your secret code.  Please enter it below with your new password",
      fi : "Lähetimme sinulle sähköpostiisi salasanan vaihdossa tarvittavan koodin.  Kirjoita se alle",
      se : ""
   }
   // twig/vue/login/Login.vue <p id="sup_title" class="modal-card-title"><Jtr>Welcome to Opetusaineistot</Jtr></p>
   ,{
      id : "Welcome to Opetusaineistot",
      fi : "Tervetuloa Opetusaineistot-palvelun käyttäjäksi",
      se : ""
   }
   // twig/vue/login/Login.vue <input v-model="reset_dialog.nonce" name="nonce" class="input" id="res_nonce" type="text" :placeholder="jtre('Your secret code')">
   ,{
      id : "Your secret code",
      fi : "Salasananvaihtokoodi",
      se : ""
   }
   ,{
      id : "Your email address: ",
      fi : "Sähköpostiosoitteesi: ",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("Internal error - cannot change password");
   ,{
      id : "Internal error - cannot change password",
      fi : "Sisäinen virhe - salasanan vaihto epäonnistui",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("Please type the same password to both fields");
   ,{
      id : "Please type the same password to both fields",
      fi : "Kirjoita sama salasana molempiin salasanakenttiin",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("Unknown error");
   ,{
      id : "Unknown error",
      fi : "Tuntematon virhe",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("You typed wrong secret code - try again");
   ,{
      id : "You typed wrong secret code - try again",
      fi : "Annoit väärän koodin - yritä uudestaan",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("Your password is too long");
   ,{
      id : "Your password is too long",
      fi : "Salasanasi on liian pitkä",
      se : ""
   }
   // twig/vue/login/Login.vue self.reset_dialog.msg = self.jtre("Your password may be cracable");
   ,{
      id : "Your password may be cracable",
      fi : "Salasanasi saattaa olla helposti murrettavissa",
      se : ""
   }
   ,{
      id : "Please use either your email or username - firstname.lastname is not in use",
      fi : "Käytä joko sähköpostiosoitettasi tai käyttäjätunnustasi - etunimi.sukunimi ei ole käytössä",
      se : ""
   }
   ,{
      id : "Product names - type enough letters from the start (separate with commas)",
      fi : "Tuotenimet (erota pilkuilla)",
      se : ""
   }
   // vue/generated/school/School.vue <th><abbr :title="jtre('Municipality - where this school is located')"><Jtr>Municipality</Jtr></abbr></th>
   ,{
      id : "Municipality - where this school is located",
      fi : "Kunta - missä koulu sijaitsee",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue label : this.jtre("Names"),
   ,{
      id : "Names",
      fi : "Nimet",
      se : ""
   }
   // vue/generated/pub_school/PublisherSchools.vue label : this.jtre("Product names"),
   ,{
      id : "Product names",
      fi : "Tuotenimet",
      se : ""
   }
   // vue/generated/pub_school/PublisherSchools.vue title : this.jtre("Product names - you don't need to write the whole name, just type enough letters to identify the product (separate with commas)"),
   ,{
      id : "Product names - you don't need to write the whole name, just type enough letters to identify the product (separate with commas)",
      fi : "Tuotenimet - sinun ei tarvitse kirjoittaa koko nimeä, kirjoita vain sen verran, että saat tunnistettua tuotteen (erottele pilkuilla)",
      se : ""
   }
   // vue/generated/school/School.vue <th><abbr :title="jtre('School group name - this is visible when someone logs in with the school group username')"><Jtr>School group name</Jtr></abbr></th>
   ,{
      id : "School group name - this is visible when someone logs in with the school group username",
      fi : "Kouluryhmän nimi - tämä on näkyvillä, kun joku kirjautuu sisälle kouluryhmän käyttäjätunnuksella",
      se : ""
   }
   // vue/generated/school/School.vue <th><abbr :title="jtre('School group username - school students log in with this when using school licenses')"><Jtr>School group username</Jtr></abbr></th>
   ,{
      id : "School group username - school students log in with this when using school licenses",
      fi : "Kouluryhmän käyttäjätunnus - tällä koulun oppilaat kirjautuvat käyttäessään koululle hankittuja digitaalisia oppimateriaaleja",
      se : ""
   }
   // vue/generated/school/School.vue <th><abbr :title="jtre('School name - the name of this school')"><Jtr>School name</Jtr></abbr></th>
   ,{
      id : "School name - the name of this school",
      fi : "Koulun nimi - tämän koulun nimi",
      se : ""
   }
   // vue/generated/school/School.vue <th v-if="['admin'].indexOf(member.role) > -1"><abbr :title="jtre('School password - school teachers use this when registering to the system')"><Jtr>School secret</Jtr></abbr></th>
   ,{
      id : "School password - school teachers use this when registering to the system",
      fi : "Koulun salasana - koulun opettajat käyttävät tätä, kun he rekisteröityvät järjestelmän käyttäjiksi",
      se : ""
   }
   // vue/generated/test/mstest.vue <h2 class="title is-2"><Jtr>Testaa materiaalivarastoa</Jtr></h2>
   ,{
      id : "Testaa materiaalivarastoa",
      fi : "Testaa materiaalivarastoa",
      se : ""
   }
   // vue/generated/school/School.vue <th v-if="['admin'].indexOf(member.role) > -1"><abbr :title="jtre('User prefix - a prefix for new student usernames - needed only when adding new students')"><Jtr>User prefix</Jtr></abbr></th>
   ,{
      id : "User prefix - a prefix for new student usernames - needed only when adding new students",
      fi : "Käyttäjätunnuksen alkuosa - alkuosa, joka liitetään uusien opiskelijoiden käyttäjätunnuksiin - tarvitaan vain, kun koululle lisätään oppilaita",
      se : ""
   }
   // vue/generated/school/School.vue <abbr :title="jtre('Add intern')">
   ,{
      id : "Add intern",
      fi : "Lisää harjoittelija",
      se : ""
   }
   // vue/generated/school/School.vue <p class="modal-card-title"><Jtr>Cancel intern status</Jtr> </p>
   ,{
      id : "Cancel intern status",
      fi : "Poista harjoittelija koulusta",
      se : ""
   }
   // vue/generated/school/School.vue <Jtr>Cancel intern status!</Jtr>
   ,{
      id : "Cancel intern status!",
      fi : "Poista harjoittelija!",
      se : ""
   }
   // vue/generated/school/School.vue <abbr :title="jtre('Delete intern')">
   ,{
      id : "Delete intern",
      fi : "Poista harjoittelija",
      se : ""
   }
   // vue/generated/school/School.vue <p id="sup_title" class="modal-card-title"><Jtr>Intern</Jtr></p>
   ,{
      id : "Intern",
      fi : "Harjoittelija",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('Intern created!');
   ,{
      id : "Intern created!",
      fi : "Harjoittelija on lisätty!",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('Intern info updated!');
   ,{
      id : "Intern info updated!",
      fi : "Harjoittelijan tiedot on päivitetty!",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('Intern removed from school!');
   ,{
      id : "Intern removed from school!",
      fi : "Harjoittelija on poistettu koulusta!",
      se : ""
   }
   // vue/generated/school/School.vue <Jtr>Interns</Jtr>
   ,{
      id : "Interns",
      fi : "Harjoittelijat",
      se : ""
   }
   // vue/generated/school/School.vue <Jtr>Please give Intern info here.</Jtr>
   ,{
      id : "Please give Intern info here.",
      fi : "Anna harjoittelijan tiedot tässä.",
      se : ""
   }
   // vue/generated/school/School.vue <abbr :title="jtre('Update intern info')">
   ,{
      id : "Update intern info",
      fi : "Päivitä harjoittelijan tiedot",
      se : ""
   }
   // vue/generated/school/School.vue <abbr :title="jtre('Update intern licenses')">
   ,{
      id : "Update intern licenses",
      fi : "Päivitä harjoittelijan lisenssit",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('User created!');
   ,{
      id : "User created!",
      fi : "Käyttäjä on lisätty!",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('User info updated!');
   ,{
      id : "User info updated!",
      fi : "Käyttäjän tiedot on päivitetty!",
      se : ""
   }
   // vue/generated/school/School.vue <p><Jtr>You are about to remove the following intern from school. Are you sure?</Jtr></p>
   ,{
      id : "You are about to remove the following intern from school. Are you sure?",
      fi : "Olet poistamassa seuraavan harjoittelijan koulusta. Oletko varma tästä?",
      se : ""
   }
   // vue/generated/school/School.vue <td><Jtr>{{ intern.role}}</Jtr></td>
   ,{
      id : "intern",
      fi : "Harjoittelija",
      se : ""
   }
   // vue/generated/personal/Personal.vue <Jtr>{{ membership_delete_dialog.data.role }}</Jtr>
   ,{
      id : "{{ membership_delete_dialog.data.role }}",
      fi : "",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue <td><Jtr>{{ student.role}}</Jtr></td>
   ,{
      id : "{{ student.role}}",
      fi : "",
      se : ""
   }
   // vue/generated/school/School.vue self.msg = self.jtre('Intern licenses updated!');
   ,{
      id : "Intern licenses updated!",
      fi : "Harjoittelijoiden lisenssit on päivitetty!",
      se : ""
   }
   // vue/generated/school/School.vue <td><Jtr>{{ intern.role}}</Jtr></td>
   ,{
      id : "{{ intern.role}}",
      fi : "",
      se : ""
   }
   // vue/generated/licenses/Licenses.vue <h2 class="title is-2"><Jtr>License permalinks</Jtr></h2>
   ,{
      id : "License permalinks",
      fi : "Pysyvät linkit materiaaleihin",
      se : ""
   }
   // vue/generated/licenses/Licenses.vue label : this.jtre("Permalink"),
   ,{
      id : "Permalink",
      fi : "Pysyvä linkki",
      se : ""
   }
   // vue/generated/licenses/Licenses.vue title : this.jtre("Permanent link"),
   ,{
      id : "Permanent link",
      fi : "Pysyvä linkki",
      se : ""
   }
   // twig/vue/components/Footer.vue eHelpDesk: <Jtr>Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm</Jtr>
   ,{
      id : "Phone 010 345 1550 Mon - Fri 7:30am - 8:30pm",
      fi : "puh: 010 345 1550 ma-pe 7:30 - 20:30",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <abbr :title="jtre('Add a group')">
   ,{
      id : "Add a group",
      fi : "Lisää ryhmä",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue <abbr :title="jtre('Add substitute teacher or intern')">
   ,{
      id : "Add substitute teacher or intern",
      fi : "Lisää sijaisopettaja tai harjoittelija",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <h3 v-if="member.role == 'admin'" class="title is-3"><Jtr>All groups</Jtr>
   ,{
      id : "All groups",
      fi : "Kaikki ryhmät",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue <abbr :title="jtre('Become school admin')">
   ,{
      id : "Become school admin",
      fi : "Ryhdy koulusihteeriksi",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre("Create new group"),
   ,{
      id : "Create new group",
      fi : "Perusta ryhmä",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue label : this.jtre("Delete"),
   ,{
      id : "Delete",
      fi : "Poista",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre("Delete group"),
   ,{
      id : "Delete group",
      fi : "Poista ryhmä",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue label : this.jtre("Edit"),
   ,{
      id : "Edit",
      fi : "Muokkaa",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre("Edit group"),
   ,{
      id : "Edit group",
      fi : "Muokkaa ryhmää",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue self.msg = self.jtre("Group created");
   ,{
      id : "Group created",
      fi : "Ryhmä on perustettu",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue self.msg = self.jtre("Group deleted");
   ,{
      id : "Group deleted",
      fi : "Ryhmä on poistettu",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <label class="label"><Jtr>Group name</Jtr></label>
   ,{
      id : "Group name",
      fi : "Ryhmän nimi",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <label class="label"><Jtr>Group owner</Jtr></label>
   ,{
      id : "Group owner",
      fi : "Ryhmän omistaja",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue self.msg = self.jtre("Group updated");
   ,{
      id : "Group updated",
      fi : "Ryhmän tiedot on päivitetty",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue button_text : this.jtre('Insert group')
   ,{
      id : "Insert group",
      fi : "Lisää ryhmä",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue self.msg = this.jtre("Licenses updated!");
   ,{
      id : "Licenses updated!",
      fi : "Lisenssit on päivitetty!",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <p id="sup_title" class="modal-card-title"><Jtr>Manage group</Jtr></p>
   ,{
      id : "Manage group",
      fi : "Hallinnoi ryhmää",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue <p id="sup_title" class="modal-card-title"><Jtr>Manage license uses</Jtr></p>
   ,{
      id : "Manage license uses",
      fi : "Jaa lisenssejä",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre('New group name'),
   ,{
      id : "New group name",
      fi : "Ryhmän nimi",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre("Number of students"),
   ,{
      id : "Number of students",
      fi : "Opiskelijoita",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue title : this.jtre("Number of teachers"),
   ,{
      id : "Number of teachers",
      fi : "Opettajia",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue label : this.jtre("Owner"),
   ,{
      id : "Owner",
      fi : "Omistaja",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <h2 class="title is-2"><Jtr>School groups</Jtr></h2>
   ,{
      id : "School groups",
      fi : "Kouluryhmät",
      se : ""
   }
   // vue/generated/test/prtest.vue <h2 class="title is-2"><Jtr>Testaa propentusta</Jtr></h2>
   ,{
      id : "Testaa propentusta",
      fi : "Testaa propentusta",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <h3 v-else class="title is-3"><Jtr>Your groups</Jtr>
   ,{
      id : "Your groups",
      fi : "Sinun ryhmäsi",
      se : ""
   }
   // twig/vue/components/Navbar.vue <a v-if="['teacher','admin'].indexOf(member.role) > -1" href="/v1/student_groups/index" class="navbar-item"><Jtr>Groups</Jtr></a>
   ,{
      id : "Groups",
      fi : "Ryhmät",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <button class="button is-info" @click="changeOwner()"><Jtr>Change owner</Jtr></button>
   ,{
      id : "Change owner",
      fi : "Vaihda omistaja",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <label class="label"><Jtr>Current group owner</Jtr></label>
   ,{
      id : "Current group owner",
      fi : "Nykyinen ryhmän omistaja",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <p id="sup_title" class="modal-card-title"><Jtr>Manage group owner</Jtr></p>
   ,{
      id : "Manage group owner",
      fi : "Hallinnoi ryhmän omistajaa",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue <button v-bind:class="{ 'is-loading' : group_dialog.processing }" class="button is-primary" v-on:click="updateOwner()"><Jtr>Update owner</Jtr></button>
   ,{
      id : "Update owner",
      fi : "Päivitä omistaja",
      se : ""
   }
   // twig/vue/students/ListStudentsV2.vue <h2 class="title is-2"><Jtr>School students</Jtr></h2><a href="?bare=1"><Jtr>Printable list...</Jtr></a>
   ,{
      id : "Printable list...",
      fi : "Tulostuskelpoinen lista...",
      se : ""
   }
   // vue/generated/personal/Personal.vue <Jtr>Please update your personal info here.</Jtr>
   ,{
      id : "Please update your personal info here.",
      fi : "Täällä saat päivitettyä tietosi",
      se : ""
   }
   // vue/generated/personal/Personal.vue <button v-if="identity != null && ['admin','teacher','high'].indexOf(member.role) > -1" class="button is-primary" @click="updatePersonalInfo()"><Jtr>Update personal info</Jtr></button>
   ,{
      id : "Update personal info",
      fi : "Päivitä tiedot",
      se : ""
   }
   // twig/vue/login/Login.vue self.msg = this.jtre("We have emailed you a link ") + response.data.link;
   ,{
      id : "We have emailed you a link ",
      fi : "Sähköpostiisi on lähetetty linkki",
      se : ""
   }
   // twig/vue/login/Login.vue <button class="button is-primary" v-on:click="bannerOk()"><Jtr>Accept cookies</Jtr></button>
   ,{
      id : "Accept cookies",
      fi : "Hyväksy evästeet",
      se : ""
   }
   // twig/vue/login/Login.vue <p><Jtr>All the cookies in Opetusaineistot are necessary for the system.  We use cookies only to ensure proper Opetusaineistot functionality.</Jtr></p>
   ,{
      id : "All the cookies in Opetusaineistot are necessary for the system.  We use cookies only to ensure proper Opetusaineistot functionality.",
      fi : "Kaikki Opetusaineistojen käyttämät evästeet ovat välttämättömiä järjestelmälle.  Käytämme evästeitä vain varmistamaan järjestelmän oikean toiminnan.",
      se : ""
   }
   // twig/vue/login/Login.vue <p><Jtr>By clicking "Accept cookies" you agree that Opetusaineistot can store cookies on your device and use cookies to offer the service.</Jtr></p>
   ,{
      id : "By clicking \"Accept cookies\" you agree that Opetusaineistot can store cookies on your device and use cookies to offer the service.",
      fi : "Valitsemalla \"Hyväksy evästeet\" annat Opetusaineistot-palvelulle luvan tallentaa evästeitä laitteellesi ja käyttää tallennettuja evästeitä palvelussa.",
      se : ""
   }
   // twig/vue/login/Login.vue <button class="button is-light" v-on:click="bannerCancel()"><Jtr>Reject cookies</Jtr></button>
   ,{
      id : "Reject cookies",
      fi : "Älä hyväksy evästeitä",
      se : ""
   }
   // twig/vue/login/Login.vue <p id="sup_title" class="modal-card-title"><Jtr>We use cookies</Jtr></p>
   ,{
      id : "We use cookies in Opetusaineistot",
      fi : "Käytämme evästeitä Opetusaineistot-palvelussamme",
      se : ""
   }
   // twig/vue/login/Login.vue <p><Jtr>We use cookies to ensure that Opetusaineistot works properly.  Cookies are used in sessions and chat.  The cookies include system generated identities and ip addresses.</Jtr></p><br>
   ,{
      id : "We use cookies to ensure that Opetusaineistot service is available.  Cookies are used in sessions and chat.",
      fi : "Käytämme evästeitä varmistaaksemme, että Opetusaineistot-palvelu on käytettävissä.  Evästeitä käytetään istuntojen ja chatin toteutuksessa.",
      se : ""
   }
   // twig/vue/login/Login.vue <!-- <p><Jtr>By clicking "Accept cookies" you agree that Opetusaineistot can store cookies on your device and use cookies to offer the service.</Jtr></p> -->
   ,{
      id : "By clicking \"Accept cookies\" you agree that Opetusaineistot can store cookies on your device and use cookies to offer the service.",
      fi : "Valitsemalla \"Hyväksyn evästeet\" hyväksyt, että Opetusaineistot tallettaa evästeitä laitteellesi ja käyttää evästeitä palvelun toteuttamiseen.",
      se : ""
   }
   // twig/vue/login/Login.vue <button class="button is-gray" v-on:click="bannerCancel()"><Jtr>I do not accept the use of cookies</Jtr></button>
   ,{
      id : "I do not accept the use of cookies",
      fi : "En hyväksy evästeiden käyttöä",
      se : ""
   }
   // twig/vue/login/Login.vue <button class="button is-primary" v-on:click="bannerOk()"><Jtr>I understand and i accept the use of cookies</Jtr></button>
   ,{
      id : "I understand and i accept the use of cookies",
      fi : "Ymmärrän ja hyväksyn evästeiden käytön",
      se : ""
   }
   // twig/vue/login/Login.vue this.gdprMsg = this.jtre("Opetusaineistot is not functionable without cookies.  Please refresh the screen if you want to reconsider your agreement");
   ,{
      id : "Opetusaineistot is not functionable without cookies.  Please refresh the screen if you want to reconsider your agreement",
      fi : "Opetusaineistot ei toimi ilman evästeitä.  Lataa tämä sivu uudestaan, jos haluat muuttaa evästevalintaasi",
      se : ""
   }
   // twig/vue/login/Login.vue <button class="button is-primary" @click="reloadBanner()"><Jtr>Ask again</Jtr></button>
   ,{
      id : "Ask again",
      fi : "Kysy uudestaan",
      se : ""
   }
   // twig/vue/login/Login.vue this.gdprMsg = this.jtre("Opetusaineistot is not functionable without cookies");
   ,{
      id : "Opetusaineistot is not functionable without cookies",
      fi : "Opetusaineistot ei toimi ilman evästeitä",
      se : ""
   }
   ,{
      id : "Too many selected license holders",
      fi : "Liikaa valittuja lisenssinhaltijoita - lisenssien määrä ei riitä",
      se : ""
   }
   ,{
      id : "Terms of Use",
      fi : "Käyttöehdot",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Intern family name must be at least one character');
   ,{
      id : "Intern family name must be at least one character",
      fi : "Harjoittelijan sukunimessä pitää olla ainakin yksi merkki",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Intern given name must be at least one character');
   ,{
      id : "Intern given name must be at least one character",
      fi : "Harjoittelijan etunimessä pitää olla ainakin yksi merkki",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Intern password must be at least eight characters');
   ,{
      id : "Intern password must be at least eight characters",
      fi : "Harjoittelijan salasanan pitää olla ainakin kahdeksan merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Intern username must be at least eight characters');
   ,{
      id : "Intern username must be at least eight characters",
      fi : "Harjoittelijan käyttäjätunnuksen pitää olla ainakin kahdeksan merkin pituinen",
      se : ""
   }
   // vue/generated/personal/Personal.vue this.dmsg = this.jtre("Please give at least one character in your family name");
   ,{
      id : "Please give at least one character in your family name",
      fi : "Anna sukunimeesi ainakin yksi merkki",
      se : ""
   }
   // vue/generated/personal/Personal.vue this.dmsg = this.jtre("Please give at least one character in your given name");
   ,{
      id : "Please give at least one character in your given name",
      fi : "Anna etunimeesi ainakin yksi merkki",
      se : ""
   }
   // vue/generated/student_groups/StudentGroups.vue this.dmsg = this.jtre("Please give at least two characters in the group name");
   ,{
      id : "Please give at least two characters in the group name",
      fi : "Anna ryhmällesi vähintään kahden merkin pituinen nimi",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre("School group password must be at least eight characters");
   ,{
      id : "School group password must be at least four characters",
      fi : "Kouluryhmän salasanan pitää olla ainakin neljän merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre("School group username must be at least four characters");
   ,{
      id : "School group username must be at least four characters",
      fi : "Koulun ryhmätunnuksen pitää olla ainakin neljän merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre("School local prefix must be at least three characters");
   ,{
      id : "School local prefix must be at least three characters",
      fi : "Koulun käyttäjätunnuksen alkuosan pitää olla ainakin kolmen merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre("School password must be at least four characters");
   ,{
      id : "School password must be at least four characters",
      fi : "Koulun salasanan pitää olla ainakin neljän merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Teacher family name must be at least one character');
   ,{
      id : "Teacher family name must be at least one character",
      fi : "Opettajan sukunimen pitää olla ainakin yhden merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Teacher given name must be at least one character');
   ,{
      id : "Teacher given name must be at least one character",
      fi : "Opettajan etunimen pitää olla ainakin yhden merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Teacher password must be at least eight characters');
   ,{
      id : "Teacher password must be at least eight characters",
      fi : "Opettajan salasanan pitää olla ainakin kahdeksan merkin pituinen",
      se : ""
   }
   // vue/generated/school/School.vue this.dmsg = this.jtre('Teacher username must be at least eight characters');
   ,{
      id : "Teacher username must be at least eight characters",
      fi : "Opettajan käyttäjätunnuksen pitää olla ainakin kahdeksan merkin pituinen",
      se : ""
   }
   // twig/vue/students/AddStudents.vue <p><Jtr>Like this: Koulu-Aapo;S11perp@@ssw9rd;Aapo;Aapola;1A</Jtr></p>
   ,{
      id : "Like this: Koulu-Aapo;S11perp@@ssw9rd;Aapo;Aapola;1A",
      fi : "Esim. näin: Koulu-Aapo;S11perp@@ssw9rd;Aapo;Aapola;1A",
      se : ""
   }
   // twig/vue/students/AddStudents.vue <p><Jtr>Please put each student to a single line:</Jtr></p>
   ,{
      id : "Please put each student to a single line:",
      fi : "Lisää jokainen oppilas .csv-tiedostoon omalle riville:",
      se : ""
   }
   // twig/vue/students/AddStudents.vue <p><Jtr>You can leave any field empty if you like</Jtr></p>
   ,{
      id : "You can leave any field empty if you like",
      fi : "Voit halutessasi jättää kenttiä tyhjiksi",
      se : ""
   }
   // twig/vue/students/AddStudents.vue <p><Jtr>username;password;first name;last name;class</Jtr></p>
   ,{
      id : "username;password;first name;last name;class",
      fi : "käyttäjätunnus;salasana;etunimi;sukunimi;luokka",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue this.msg = self.jtre("Amount must be a positive number");
   ,{
      id : "Amount must be a positive number",
      fi : "Määrän pitää olla luku ja vähintään yksi",
      se : ""
   }
   // vue/generated/pub_school_admin/PublisherSchoolAdmin.vue this.msg = this.jtre("Please give at least one isbn or product name");
   ,{
      id : "Please give at least one isbn or product name",
      fi : "Anna ainakin yksi isbn tai tuotenimi",
      se : ""
   }
];
